import { type LoaderFunctionArgs } from '@remix-run/node';
import {
  type MetaFunction,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useState } from 'react';
import { $path } from 'remix-routes';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import config from '../../src/config';
import { apiService } from '../../src/services/api-service';
import { GamePackBreadcrumbs } from '../components/GamePack/GamePackBreadcrumbs';
import {
  type GameLikeFilterOptions,
  toGameLikeFilters,
} from '../components/GamePack/GamePackFilters';
import { makeAnonPackUrl } from '../components/GamePack/utils';
import { MetadataUtils } from '../components/metadata';
import { AnonGamePackCard } from '../components/PublicLibrary/AnonGamePack';
import { AnonPublicLibraryLayout } from '../components/PublicLibrary/AnonPublicLibraryLayout';
import { AnonPublicLibraryNav } from '../components/PublicLibrary/AnonPublicLibraryNav';
import {
  encodeFilterOptions,
  parseFilterOptions,
  PublicLibraryFilter,
  PublicLibraryFilterKeysAllLoggedOut,
} from '../components/PublicLibrary/PublicLibraryFilter';
import { PublicLibraryGamePackList } from '../components/PublicLibrary/PublicLibraryLayout';
import { useExploreData } from './explore';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const params = new URL(request.url).searchParams;
  const filterOptions = parseFilterOptions(params);
  const q = params.get('q') || '';

  const packs = await apiService.gamePack
    .searchGamePacks(q, {
      filterParams: toGameLikeFilters(filterOptions),
      primeOnly: true,
      scope: EnumsPageName.PageNameExplore,
    })
    .next();

  return {
    q: params.get('q'),
    filterOptions,
    packs,
  };
};

export const meta: MetaFunction<typeof loader> = () => {
  return MetadataUtils.Make({
    title: 'Matched Experiences',
    desc: 'Browse Luna Park’s vast library of virtual team building experiences. Designed for remote & hybrid teams by professional game artists',
    url: new URL('/explore/search', config.app.baseUrl).href,
    image: 'generic',
  });
};

export default function Component() {
  const exploreData = useExploreData();
  const { filterOptions, packs } = useLoaderData<typeof loader>();
  const navigate = useNavigate();

  const handleApply = (options: Partial<GameLikeFilterOptions> | null) => {
    if (!options) return;

    const params = encodeFilterOptions(options, {
      init: new URLSearchParams(window.location.search),
    });
    navigate({
      search: params.toString(),
    });
  };

  const [filters, setFilters] =
    useState<Partial<GameLikeFilterOptions>>(filterOptions);

  return (
    <AnonPublicLibraryLayout
      {...exploreData}
      secondaryNav={
        <AnonPublicLibraryNav
          pageName={exploreData.pageName}
          page={exploreData.page}
        />
      }
    >
      <div className='w-full p-10 flex flex-col gap-10'>
        <GamePackBreadcrumbs
          items={[
            { label: 'Explore', to: $path('/explore') },
            { label: 'Matched Experiences' },
          ]}
        />

        <div className='w-full flex justify-center'>
          <PublicLibraryFilter
            showMenuKeys={PublicLibraryFilterKeysAllLoggedOut}
            filterOptions={filters}
            onChange={setFilters}
            onApply={handleApply}
          />
        </div>

        {packs.length > 0 ? (
          <PublicLibraryGamePackList
            packs={packs}
            renderCard={(pack) => (
              <AnonGamePackCard
                pack={pack}
                onClick={() => navigate(makeAnonPackUrl(pack))}
              />
            )}
          />
        ) : (
          <div className='text-secondary text-center'>
            No Matching Experiences Found
          </div>
        )}
      </div>
    </AnonPublicLibraryLayout>
  );
}
